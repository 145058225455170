<template>
  <div>
    <!-- Kundensuche -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn @click="dialogOpen = true" icon v-on="on" class="ma-0" style="background-color:#FF6969;">
          <font-awesome-icon :icon="['fal', 'user-friends']" style="font-size: 20px; color:white;" />
        </v-btn>
      </template>
      <span>{{ $t('customers.lang_cust_search') }}</span>
    </v-tooltip>

    <v-dialog max-width="700px" scrollable v-model="dialogOpen">
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('customers.lang_cust_search') }}</v-toolbar-title>

          <v-spacer />

          <v-btn @click="closeDialog" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0" />

        <v-text-field style="margin-top: 35px;" outlined :label="$t('generic.lang_custSearchLabel')"
          :placeholder="$t('generic.lang_custSearchLabel')" v-model="searchInput" :loading="loading"
          @focus="showTouchKeyboard" autofocus :data-layout="KEYBOARD.KEYSETS.NORMAL"
          ref="customerSearch"></v-text-field>


        <!-- Show Search results -->
        <v-card-text style="margin-top: 0px; padding: 0;">
          <v-list v-if="!loading && selectedCustomer === null">
            <v-list-item @click="loadCustomer(customerItem)" v-for="customerItem in showCustomers"
              :key="customerItem.id">
              <v-list-item-content>
                <v-list-item-title>{{ customerItem.text }}</v-list-item-title>
                <v-list-item-subtitle>{{ customerItem.company }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ customerItem.street }} {{ customerItem.streetNumber }} {{ customerItem.zip }}
                  {{ customerItem.city }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-if="selectedCustomer !== null">
          <v-row>
            <v-col cols="12">
              <button
                :class="selectedProject === p.projectUUID? 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom btn-primary border-none text-light' : 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom'"
                :key="i" @click="setProject(p)" v-bind:style="{
                border: '1px solid ' + p.projectColor,
                color: p.projectColor
              }" v-for="(p , i) in this.projects">{{ p.projectName }}
              </button>
            </v-col>
          </v-row>

          <!-- Loading -->
          <v-col v-if="loadingCustomerDetails" cols="12" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-card-text>

        <v-card-text>
          <v-form lazy-validation ref="projectFrom" style="margin-top: 10px;">
            <users :rules="[v => !!v]" dense filter="isServiceProvider=1"
              :label="$t('generic.lang_selectServiceProvider')" outlined v-bind:user="this.user" v-if="this.showUsers"
              v-model="user" />
          </v-form>
        </v-card-text>

        <v-divider class="ma-0" />

        <v-card-actions>
          <v-btn @click="closeDialog" color="error" text class="fontTextSize">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer />
          <div v-if="$vuetify.breakpoint.smAndDown"><br><br></div>

          <div class="d-flex flex-wrap justify-end">
            <v-btn @click="showCreateCustomerDialog = true" color="primary" text
              v-if="this.$store.getters['permissions/checkPermission']('create_cust')" class="fontTextSize">{{
                $t('customers.lang_create_NewCust') }}
            </v-btn>
            <v-btn v-if="showPayVOffice" :disabled="loading || selectedCustomer === null" :loading="loading"
              @click="payVOffice" color="primary" class="fontTextSize">{{ $t('generic.lang_payVOffice') }}
            </v-btn>

            <v-btn :disabled="loading || selectedCustomer === null" :loading="loading" @click="setCustomer()"
              color="success" class="fontTextSize">{{ $t('generic.lang_selectCustomer') }}
            </v-btn>
          </div>

        </v-card-actions>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
            :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input" :layout="touchKeyboard.layout"
            :options="touchKeyboard.options" class="internalWidthExpanded" id="onScreenKeyboard"
            v-if="touchKeyboard.visible" />
        </div>
      </v-card>

      <CustomerCreateDialog :pos-type="posType" :show-dialog="showCreateCustomerDialog"
        @closeDialog="showCreateCustomerDialog = false" @customerCreated="customerCreated"
        v-if="this.$store.getters['permissions/checkPermission']('create_cust')" />
    </v-dialog>
  </div>
</template>

<style scoped>
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

@media (max-width: 1024px) {
  .fontTextSize {
    font-size: 0.480rem !important;
    margin: 0px !important;
  }
}

@media (min-width: 1025px) {
  .fontTextSize {
    font-size: 0.875rem !important;
  }
}
</style>

<script>
import randomString from "randomstring";
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserFriends} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import CustomerCreateDialog from "../CustomerCreateDialog";
import Users from "@/components/common/Users";
import mixin from "../../../mixins/KeyboardMixIns";

library.add(
    faUserFriends
);

export default {
  name: "InvoiceCustomerSearch",

  props: {
    posType: String
  },

  mixins: [mixin],

  components: {
    Users,
    CustomerCreateDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },

  data() {
    return {
      dialogOpen: false,
      searchInput: "",
      awaitingSearch: null,
      projects: [],
      loading: false,
      showCustomers: [],
      showCreateCustomerDialog: false,
      selectedCustomer: null,
      showUsers: false,
      selectedProject: "",
      user: null,
      loadingCustomerDetails: false,
      customerDetails: null,
      ignoreSearchInputWatcher: false,
      customerVOfficeBookingToken: null,
    }
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    searchInput(val) {
      if (this.ignoreSearchInputWatcher)
        return;

      // RESET CUSTOMER
      this.selectedCustomer = null;
      this.customerVOfficeBookingToken = null;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        val && val !== this.select && this.searchForItem(val)
      }, 1000);
    },
    showCreateCustomerDialog(val) {
      if (val) {
        this.$refs.customerSearch.blur();
        this.customerVOfficeBookingToken = null;
      }
    },
    currentPartyName(v){
      if(this.posType !== 'gastro' || this.tableName == 0)
        return;

      if(this.orderUUIDS[v] && this.orderUUIDS[v].customer_id){
        this.setCustomer(this.orderUUIDS[v].customer_id)
      } else {
        this.removeCustomer();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPartyName: 'pos/gastro/currentPartyName',
      orderUUIDS: 'pos/gastro/orderUUIDS',
      tableName: 'pos/gastro/tableName',
    }),
    showPayVOffice(){
      return this.$store.getters['permissions/checkModule'](51) && this.customerVOfficeBookingToken && this.customerVOfficeBookingToken.length > 8
    }
  },
  mounted(){
    this.$root.$on('updateCustomer', ()=>{
      if(this.posType !== 'gastro' || this.tableName == 0)
        return;

      if(this.orderUUIDS[this.currentPartyName] && this.orderUUIDS[this.currentPartyName].customer_id){
        this.setCustomer(this.orderUUIDS[this.currentPartyName].customer_id)
      } else {
        this.removeCustomer();
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('updateCustomer')
  },
  methods: {
    setProject(p) {
      this.showUsers = true;
      this.selectedProject = p;
    },
    setCustomerProject() {
      this.$store.dispatch("pos/setCustomerProject", {
        posType: this.posType,
        project: {
          project: this.selectedProject,
          user_id: this.user
        }
      })

    },
    setPartyCustomer(customerID){
      if(this.posType !== 'gastro' || this.tableName == 0)
        return;

      this.$store.dispatch('pos/gastro/setOrderUUIDSData', {
        partyName: this.currentPartyName,
        data: {
          ...(this.orderUUIDS[this.currentPartyName] || {}),
          customer_id: customerID
        }
      });
      return;
    },
    removeCustomer() {
      this.$store.dispatch("pos/setCustomer", {
        posType: this.posType,
        customer: null
      });
    },
    loadCustomer(customer) {
      this.selectedCustomer = customer.id;
      this.customerVOfficeBookingToken = customer.vOfficeBookingToken
      this.ignoreSearchInputWatcher = true;

      this.$nextTick(() => {
        this.searchInput = customer.text;

        this.$nextTick(() => {
          this.ignoreSearchInputWatcher = false;
        });
      });

      //GET PROJECTS
      this.axios.post(ENDPOINTS.CUSTOMERS.PROJECTS.GETALL, {
        ascCustomerId: customer.id
      }).then((res) => {
        if (res.data.success) {
          this.projects = res.data.projects;
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      });
    },
    closeDialog() {
      //RESET DATA
      this.resetData();

      this.dialogOpen = false;
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem(searchValue) {
      this.loading = true;
      //this.loadingCustomerDetails = false;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search: searchValue
        }
      }).then((res) => {
        this.loading = false;

        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.showCustomers = res.data.results;
          } else {
            this.showCustomers = [];
          }
        }
      }).catch(() => {

      });
    },
    setCustomer(id = null) {
      if(this.loading)
        return;
      if (!id && !this.$refs.projectFrom?.validate()) return;
      this.loading = true;

      const customerID = id ? id : this.selectedCustomer;

      // GET CUSTOMER
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CUSTOMERINFO, {
        customerID: customerID
      }).then((res) => {
        if (res.data.success) {
          let customer = res.data.customer;

          // GET CUSTOMER ALLERGENS
          this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GETWITHNAMES, {
            customerId: customerID
          }).then((res) => {
            if (res.data.success) {
              customer.allergens = res.data.allergens;

              //ADD CUSTOMER TO INVOICE
              this.$store.dispatch("pos/setCustomer", {
                posType: this.posType,
                customer: customer
              }).then(() => {
                this.setCustomerProject();
                this.setPartyCustomer(customerID);
                this.closeDialog();
                this.resetData();
              });
            }
          });
        } else {
          this.loading = false;

          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        this.loading = false;

        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    payVOffice() {
      this.loading = true;
      
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.CUSTOMERINFO, {
        id: this.selectedCustomer
      }).then((res) => {
        if (res.data.success) {
          const data = res.data.voffice;
          let item = {
            id: data.reservation.id,
            ean: "VOFFICEOutstanding_" + randomString.generate(16),
            name: 'VOffice ' + this.$t('generic.lang_reservation') +' '+ data.reservation.nr,
            originalSellPrice: Number((data.reservation.outstanding || 0)/100),
            sellPrice: Number((data.reservation.outstanding || 0)/100),
            selectedExtras: [],
            mealSizeID: 0,
            weight: null,
            taxValue: 0,
            depositgroupID: 0,
            discount: 0,
            warenameOverwrite: null,
            individualBookingNo: null,
          };

          if (this.posType === 'gastro') {
            this.$store.dispatch('pos/gastro/bookItem', item)
          } else if (this.posType === 'retail') {
            this.$store.dispatch('pos/retail/bookItem', item)
          }
          this.setCustomer();
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    customerCreated(customerID) {
      this.selectedCustomer = customerID;

      this.showCreateCustomerDialog = false;
      this.setCustomer();
    },
    resetData() {
      this.loading = false;
      this.searchInput = "";
      this.selectedCustomer = null;
      this.showCustomers = [];
      this.projects = [];
      this.showUsers = false;
      this.selectedProject = "";
    }
  },
}
</script>

<style scoped>
.outlined {
  outline-color: #1F9BCF !important;
}

</style>